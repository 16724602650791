@font-face {
  font-family: 'GmarketSans_L';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff')
    format('woff');
}

@font-face {
  font-family: 'GmarketSans_R';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff')
    format('woff');
}

@font-face {
  font-family: 'GmarketSans_B';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff')
    format('woff');
}

@font-face {
  font-family: 'PretendardVariable';
  font-weight: normal;
  font-display: swap;
  src: url('../../public/fonts/PretendardVariable.ttf') format('truetype');
}

@font-face {
  font-family: 'Jalnan';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pretendard_L';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pretendard_R';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pretendard_B';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins_100';
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/Poppins/Poppins-Thin.woff')
    format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins_200';
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/Poppins/Poppins-ExtraLight.woff')
    format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins_300';
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/Poppins/Poppins-Light.woff')
    format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins_400';
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/Poppins/Poppins-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins_500';
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/Poppins/Poppins-Medium.woff')
    format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins_600';
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/Poppins/Poppins-SemiBold.woff')
    format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins_700';
  src: url('https://cdn.jsdelivr.net/gh/webfontworld/Poppins/Poppins-Bold.woff')
    format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
